export const spotifyClientId = "3d79511672a14143860ad03e3c476ed5";
// export const spotifyClientId = "ef9a4d764c5448c0ac37f6be0a35d722";
export const spotifyAuthEndpoint = "https://accounts.spotify.com/authorize";
export const spotifyResponseType = "token";
export const scope = "user-top-read playlist-read-private playlist-modify-private playlist-modify-public";

export const imagesContainer = "https://urpix951d.blob.core.windows.net/urpiximages/";

export const functionsAppUrl = "https://urpix-api.azurewebsites.net";
// export const functionsAppUrl = "http://localhost:7071";

export const getShortlist = {
  endpoint: "/api/GetShortList",
  key: "qL5YiRUfOjeIko9sBkDdIIWR79v4N-zpS1VkzuvWtVWNAzFu8XVnmw=="
};

export const updateShortlist = {
  endpoint: "/api/UpdateShortList",
  key: "8LI4TXs64WmsjclC0yZdVKVCtdUwK4VIFRc9X0FbBIsAAzFuuusHog=="
};

export const getPopular = {
  endpoint: "/api/GetPopularTripleJ",
  key: "DICv656pZIRoE99BK6yf4tw7MezD8xFChNDr2rTxuXe9AzFuqvDDwg=="
};

export const updateTrackShortlist = {
  endpoint: "/api/UpdateTrackShortlist",
  key: "0ubI9lMat6usHrOFIGwRKXLfppNnP3jedGg4wokLBVKmAzFuumP66w=="
};

export const linkPlaylist = {
  endpoint: "/api/LinkPlaylist",
  key: "E_rFBzMIp_LqNIXiPtuWIxs-7KPNRDRd1rarnz280UfxAzFukzXGuQ=="
};

export const getRecentlyShortlisted = {
  endpoint: "/api/GetRecentlyShortlisted",
  key: "dePIZgkmB9RZZoxGjgWq9bzTDsTypgZ4DaAdLWLojZlhAzFuHqMqbg=="
};

export const updateCompanions = {
  endpoint: "/api/UpdateCompanions",
  key: "c6EytL4EpaY-ediCT54EcqopzFV-F32j-qY_e0GPjKZ-AzFujH7t_g=="
};

export const getCompanions = {
  endpoint: "/api/GetCompanions",
  key: "Xe0GH8bHj5acy-xWnDz3SyhfmLkg7ioUghPSacsMBiA5AzFuquLG1w=="
};

export const getCompanionsBySpotifyId = {
  endpoint: "/api/GetCompanionsBySpotifyId",
  key: "iz6eFPQGm3midLCrji_q8dfJ2IFBOHcLD3mJiMqgjprwAzFubnHYew=="
};

export const uploadImage = {
  endpoint: "/api/UploadImage",
  key: "sL8A9DwcC4In4gPITvos3IWiQEwXoN-b2kTmaqalypJ2AzFueQAfpg=="
};

export const uploadImageRaw = {
  endpoint: "/api/UploadImageRaw",
  key: "C6MWAkH0vB_2rtZbBdteBBH4zeUACRpIZ_XisPsf6uUMAzFuuFx0LA=="
}

export const addFeedbackItem = {
  endpoint: "/api/AddFeedbackItem",
  key: "53s9Pf_kZp984PUdk1zpbCmc_RyVUigX8-pW-u4AoFyGAzFu9KGUTg=="
}


export const featureToggles = {
  companions: true
}